import { en as $vuetifyEn, pt as $vuetifyPt } from 'vuetify/locale';

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    en: {
      $vuetify: $vuetifyEn,
    },
    pt: {
      $vuetify: $vuetifyPt,
    },
  },
}));
